.sponsor__wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 1vw 0.5vw;
}

.flex__center {
    flex-wrap: wrap;
}

.sponsor__img {
    width: 26.5vw;
    object-fit: contain;
}


.sponsor__section {
    background-color: var(--color-white);
}

@media screen and (max-width: 1150px) {
    .sponsor__wrapper {
        min-width: 100px;
    }

    .flex__center {
        flex-direction: column;
    }

    .sponsor__img {
        width: 55vw;
    }

}

@media screen and (max-width: 650px) {
    .sponsor__wrapper {
        min-width: 100px;

    }

    .flex__center {
        flex-direction: column;
    }

    .sponsor__wrapper {
        padding: 1rem 0.5rem;
    }

    .sponsor__img {
        width: 75vw;
    }

}

@media screen and (max-width: 450px) {
    .sponsor__img {
        width: 75vw;
    }
}
