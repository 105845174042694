.card {
    border: 1px solid #e59846;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-width: 300px;
    background-color: var(--color-bg-peach);
    overflow: hidden;
}

.card-image {
    margin: 2px 2px;
    max-height: 280px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-content {
    padding: 10px;
}


.card-description {
    margin: 3px;
}