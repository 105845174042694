.staff__card-container{
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    border-radius: 20px;
}

.staff__card-image_container{
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.staff__card-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid var(--color-orange);
}

.staff__card-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
    margin-top: 1rem;
}

@media screen and (max-width: 450px) {
    .staff__card-container{
        flex-direction: column;
    }
}
