.link__list{
    margin-top:3px;
    margin-left: 20px;
}

.link__list li:hover{
    color: var(--color-orange);
}

.article__img{

}