.app__aboutus{
    position: relative;
}

.app__aboutus-overlay{
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img{
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content{
    width: 100%;
    z-index: 2;
}


.app__aboutus-content_about {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.app__aboutus-content h1{
    text-align: center;
    color: var(--color-green);
}


.app__aboutus-content_about p{
    margin: 2rem 0;
    color: var(--color-text-black);
}


@media screen and (max-width: 900px) {
    .app__aboutus-content{
        flex-direction: column;
    }
}

