.app__video{
    height: 100vh;
    position: relative;
    background-color: var(--color-bg-green);
}

.app__video video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*video overlay si bugga e non risponde correttamente al cambio di visibilità durante l'hovering del cursore, lasciare i comandi a video per il momento e proprietà hidden*/ 

.app__video-overlay{
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.30);
    visibility: hidden;
}

.app__video-overlay_circle{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-orange);
    cursor: pointer;
    visibility: hidden;
    height: auto;
}

.app__video-overlay_circle:hover {
    visibility: visible;
}

@media screen and (max-width: 800px) {
    .app__video {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .app__video video{
        width: 100%;
        height: auto;
        max-width: 850px;
    }

}