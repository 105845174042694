
.app__wrapper-card__container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}



@media (max-width: 750px) {
    .app__wrapper-card__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        justify-content: center;
    }
}