.app__navbar {
    min-height: 10vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-white);

    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 150px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    list-style: none;
}

.app__navbar-links li {
    color: var(--color-green) !important;
    margin: 0 1rem;
    cursor: pointer;
    font-size: 21px;
    text-decoration: none !important;
}

.app__navbar-links li:hover {
    color: var(--color-orange) !important;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-white);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close{
    font-size: 40px;
    color: var(--color-orange);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-green);
    font-size: 2rem;
    text-align: center;
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-orange);
}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px){
    .app__navbar-links{
        display: none;
    }

    .app__navbar-smallscreen{
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar{
        padding: 1rem;
    }
}
