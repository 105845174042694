
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@import url('https: //fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');


:root {
  --font-base: 'Poppins', sans-serif;
  --font-alt: 'Open Sans', sans-serif;
  

  --color-bg-green: #c8e8e0;
  --color-bg-peach: #fcf4eb;
  --color-text-black: #111111;
  --color-light-green: #DEF5E5;
  --color-green: #2e937a;
  --color-orange: #e59846;
  --color-white: #f4f4f4;
  --color-golden: #DCCA87;

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
