.vertical-timeline__section{
    background-color: var(--color-bg-green);
}

.vertical-timeline__start{
    padding: 8em 0 2em;
}

.vertical-timeline-element-title{
    font-family: var(--font-base);
    color: var(--color-orange);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: none;
    font-feature-settings: 'tnum' on, 'lnum' on;
}