.app__video-resources{
    background-color: var(--color-bg-green);
    flex: 1;
    width: 100%;
    flex-direction: column;
    display: flex;
}

.app__video-section_title {
    margin-bottom: 5vh;
    justify-content:flex-start;
}

.video__title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.app__video-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;

}

.app__video-wrapper video{
    margin: 10px;
    margin-bottom: 30px;
    width: 100%;
    object-fit: contain;
    max-width: 850px;
}

