.app__wrapper_mission{
    text-align: center;
    padding: 0 5rem;
}

.mission__section{
    background-color: var(--color-bg-green);
    padding: 4rem 6rem 0rem;
}

@media screen and (max-width: 700px) {
    .app__wrapper_mission {
        padding: 0 2rem;
    }

    .mission__section {
        padding: 4rem 2rem;
    }
}