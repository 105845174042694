.app__header{
    background-color: var(--color-bg-green);
}

.app__header-h1{
    font-family: var(--font-base);
    color: var(--color-green);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: 5vw;
    line-height: 6vw;
    flex: 1;
}

.app__wrapper_img{
	width: 100%;
}

.app__video_responsive{
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-top: 56.25%;
}

.app__video_responsive iframe{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.app__header-img img{
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-top: 56.25%;
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 9vw;
        line-height: 10.8vw;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 10vw;
        line-height: 12vw;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 10vw;
        line-height: 12vw;
    }
}
