.app__footerOverlay{
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.app__footerOverlay-bg{
    height: 25%;
    background: var(--color-white);
}

.app__footerOverlay-img{
    height: 75%;
}